/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       view-edit-delete.actions-renderer.ts
 * @author     Christian Ascone
 * @date       3/23/20 5:06 PM
 */

import {Component} from '@angular/core';

@Component({
  selector: 'app-view-edit-delete-actions-renderer',
  template: `
    <div>
      <button *ngIf="!params.hideView" mat-icon-button (click)="onView(params)">
        <mat-icon>visibility</mat-icon>
      </button>
      <button *ngIf="!params.hideEdit" mat-icon-button (click)="onEdit(params)">
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="!params.hideDelete" mat-icon-button (click)="onDelete(params)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>`,
})
export class ViewEditDeleteActionsRendererComponent {


  public params: any = {
    hideView: false,
    hideEdit: false,
    hideDelete: false,
  };

  constructor() {
  }

  agInit(params): void {
    this.params = params;
  }

  public onView<T>(row: T) {
    this.params.context.componentParent.viewRenderer(this.params);
  }

  public onEdit<T>(row: T) {
    this.params.context.componentParent.editRenderer(this.params);
  }

  public onDelete<T>(row: T) {
    this.params.context.componentParent.deleteRenderer(this.params);
  }
}
