/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       constants.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

export const GENERIC_ERROR_MESSAGE = 'Servizio attualmente non disponibile';

export enum MENU_ITEMS_PATH {
  AUTH_LOGIN = '/auth/login',
  MULTI_FRAME_HOSTS = '/multi-frame/hosts',
  MULTI_FRAME_RESERVATIONS = '/multi-frame/reservations',
  MULTI_FRAME_HOUSES = '/multi-frame/houses',
  MULTI_FRAME_DEVICES = '/multi-frame/devices'
}
