/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       device.actions-renderer.ts
 * @author     Christian Ascone
 * @date       3/23/20 5:59 PM
 */

import {Component} from '@angular/core';
import {Device} from '../../apis/models';

@Component({
  selector: 'app-download-actions-renderer',
  template: `
    <div>
      <button *ngIf="!params.device.active" mat-icon-button (click)="onActivate(params)">
        <mat-icon>play_circle_filled</mat-icon>
      </button>
      <button *ngIf="params.device.active" mat-icon-button (click)="onDeactivate(params)">
        <mat-icon>stop</mat-icon>
      </button>
    </div>`,
})
export class DeviceActionsRendererComponent {


  public params: {
    device: Device,
    context: any,
  };

  constructor() {
  }

  agInit(params): void {
    this.params = params;
  }

  public onActivate<T>(row: T) {
    this.params.context.componentParent.activateRenderer(this.params);
  }

  public onDeactivate<T>(row: T) {
    this.params.context.componentParent.deactivateRenderer(this.params);
  }
}
