/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       app.component.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:15 PM
 */

import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'bfree-backoffice-client';

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('it');
  }
}
