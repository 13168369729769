/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       api-configuration-interceptor.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

import {Injectable} from '@angular/core';
import {ApiConfiguration} from 'src/app/apis/api-configuration';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApiConfigurationInterceptor implements ApiConfiguration {
  rootUrl: string = environment.apiUrl;
}
