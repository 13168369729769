/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       spinner.component.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner-component',
  templateUrl: './spinner.html',
  styleUrls: ['./spinner.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}

