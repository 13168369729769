/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       utils.module.ts
 * @author     Christian Ascone
 * @date       3/23/20 5:59 PM
 */

import {NgModule} from '@angular/core';
import {MaterialModule} from './material.module';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AuthInterceptor} from './services/auth-interceptor.service';
import {AuthService} from '../auth/auth.service';
import {ViewEditDeleteActionsRendererComponent} from '../multi-frame/grid-actions-component/view-edit-delete.actions-renderer';
import {DownloadActionsRendererComponent} from '../multi-frame/grid-actions-component/download.actions-renderer';
import {DeviceActionsRendererComponent} from '../multi-frame/grid-actions-component/device.actions-renderer';
import {EntityDialogComponent} from './entity-dialog/entity-dialog.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    MaterialModule
  ],
  declarations: [
    EntityDialogComponent,
    ViewEditDeleteActionsRendererComponent,
    DownloadActionsRendererComponent,
    DeviceActionsRendererComponent,
  ],
  entryComponents: [
    EntityDialogComponent,
    ViewEditDeleteActionsRendererComponent,
    DownloadActionsRendererComponent,
    DeviceActionsRendererComponent,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ]
})
export class UtilsModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
