/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       entity-dialog.component.ts
 * @author     Christian Ascone
 * @date       3/24/20 5:45 PM
 */

import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './entity-dialog.html',
})
export class EntityDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public dismiss() {
    this.dismiss();
  }

  // public confirm() {
  //   this.ref.close(true);
  // }
}
