/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       spinner.service.ts
 * @author     Christian Ascone
 * @date       2/28/20 10:14 AM
 */

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  constructor() {
  }

  /**
   * Shows the rotating spinner
   */
  start() {
    const spinnerElement = this.getSpinnerElement();
    if (spinnerElement) {
      spinnerElement.style.display = 'block';
    }
  }

  /**
   * Hides the rotating spinner
   */
  stop() {
    const spinnerElement = this.getSpinnerElement();
    if (spinnerElement) {
      spinnerElement.style.display = 'none';
    }
  }

  /**
   * Finds the Spinner element from view
   */
  private getSpinnerElement() {
    return document.getElementById('bfree-spinner');
  }
}
