/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       auth-interceptor.service.ts
 * @author     Christian Ascone
 * @date       2/28/20 10:13 AM
 */

import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {SpinnerService} from '../spinner/spinner.service';
import {Router} from '@angular/router';
import {catchError, finalize} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {StorageHelperService} from './storagehelper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    public spinnerService: SpinnerService,
    private authService: AuthService,
    private storageHelper: StorageHelperService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loginUrl = environment.apiUrl + environment.loginUrl;
    const headers = {};
    if (request.url !== loginUrl) {
      const token = this.storageHelper.getToken();

      if (!token) {
        this.returnToLogin();
      }
      const authKey = 'Authorization';
      headers[authKey] = `Bearer ${token}`;

    }
    request = request.clone({
      headers: new HttpHeaders(headers)
    });
    this.spinnerService.start();
    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 403) {
          err.error.message = 'Non hai il permesso per effettuare questa azione';
        }
        if (err.status === 401) {
          this.returnToLogin();
        }
        return observableThrowError(err);
      }),
      finalize(() => this.spinnerService.stop())) as any;
  }

  private returnToLogin() {
    this.authService.logout();
  }

}
