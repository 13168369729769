/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       auth.service.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {MENU_ITEMS_PATH} from '../utils/constants';
import {StorageHelperService} from '../utils/services/storagehelper.service';

@Injectable()
export class AuthService {

  constructor(
    public router: Router,
    public http: HttpClient,
    public storageService: StorageHelperService,
  ) {
  }

  logout() {
    this.storageService.logout();
    this.router.navigate([MENU_ITEMS_PATH.AUTH_LOGIN]);
  }

  writeAllCookies(access: any) {
    this.storageService.storeToken(access.access_token);
  }
}
