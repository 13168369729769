
export const environment = {
    debugRoom: false,
    production: false,
    apiUrl: 'https://api.bfree.host',
    loginUrl: '/oauth/token',
    baseDomain: '',
    cookieBase: 'bfree.prod',
    oauthClientId: '2',
    oauthClientSecret: 'UNfFGVNnLzHaTNLIo2RrWjUmiYJASrVg4MvuuH0p',
};