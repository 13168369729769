/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       download.actions-renderer.ts
 * @author     Christian Ascone
 * @date       3/23/20 5:06 PM
 */

import {Component} from '@angular/core';

@Component({
  selector: 'app-download-actions-renderer',
  template: `
    <div>
      <button mat-icon-button (click)="onDownload(params)">
        <mat-icon>cloud_download</mat-icon>
      </button>
      <button *ngIf="!params.hideDelete" mat-icon-button (click)="onDelete(params)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>`,
})
export class DownloadActionsRendererComponent {


  public params: any = {
    hideDelete: false,
  };

  constructor() {
  }

  agInit(params): void {
    this.params = params;
  }

  public onDownload<T>(row: T) {
    this.params.context.componentParent.downloadRenderer(this.params);
  }

  public onDelete<T>(row: T) {
    this.params.context.componentParent.deleteRenderer(this.params);
  }
}
