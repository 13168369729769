/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       storagehelper.service.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

const AQUA_ME = environment.cookieBase;

@Injectable({
  providedIn: 'root'
})
export class StorageHelperService {
  private BEARER_TOKEN = 'bearer_token';

  constructor() {
  }

  /**
   * Store a given item with a key
   * @param {string} key
   * @param item
   */
  setItem(key: string, item) {
    localStorage.setItem(key, item);
  }

  /**
   * Get the item identified by given key
   * @param {string} key
   * @returns {string}
   */
  getItem(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * Remove the item identified by given key
   * @param {string} key
   * @returns {string}
   */
  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  /**
   * Store the given bearer token in local storage
   * @param token
   */
  storeToken(token) {
    this.setItem(this.BEARER_TOKEN, token);
  }

  /**
   * Get the current stored bearer token from local storage
   */
  getToken() {
    return this.getItem(this.BEARER_TOKEN);
  }

  /**
   * Set the <i>me</i> object
   * @param data
   */
  setMe(data: any) {
    if (data != undefined || null) {
      this.setItem(AQUA_ME, JSON.stringify(data));
    } else {
      this.removeItem(AQUA_ME);
    }
  }

  /**
   * Get the <i>me</i> object
   * @return any | undefined
   */
  getMe() {
    return this.getDataAndParseOrUndefined(AQUA_ME);
  }

  /**
   * Remove bearer token from local storage
   */
  logout() {
    this.removeItem(this.BEARER_TOKEN);
  }

  private getDataAndParseOrUndefined(key: string) {
    const dataString = this.getItem(key);
    let parsed = undefined;
    try {
      parsed = JSON.parse(dataString);
    } catch (e) {
      console.error(e);
      parsed = !!dataString || undefined;
    }
    return parsed;
  }
}
