/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-backoffice-client.nosync
 * @file       app.routes.ts
 * @author     Christian Ascone
 * @date       2/27/20 6:26 PM
 */

import {Routes} from '@angular/router';

export const appRoutes: Routes = [
  {
    path: 'multi-frame',
    loadChildren: './multi-frame/multi-frame.module#MultiFrameModule'
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/auth/login'
  }
];
